<template>
    <div class="home">
        <nav class="nav_sech">
            <el-input class="el_input" v-model="queryform.name" placeholder="请输入名称"></el-input>
            <el-select class="el_input" v-model="queryform.state" placeholder="请选择状态">
                <el-option label="禁用" value="0"></el-option>
                <el-option label="开启" value="1"></el-option>
            </el-select>
            <el-input class="el_input" v-model="queryform.Remarks" placeholder="请输入备注"></el-input>
            <el-button class="el_btn" @click="queryenu" type="warning">查询</el-button>
            <el-button class="el_btn" @click="tableDataAdd" type="primary">新增</el-button>
        </nav>
        <div class="inte"></div>
        <main class="el_table" style="margin:15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width:100%;margin-bottom: 20px"
                    row-key="id"
                    align="center"
                    border
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                        prop="name"
                        label="名称"
                        align="left"
                        width="220">
                </el-table-column>
                <el-table-column label="类型" align="center" width="170">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type==0">左侧菜单(url)权限</span>
                            <span v-if="scope.row.type==1">普通页面链接(url)权限</span>
                            <span v-if="scope.row.type==2">页面按钮权限</span>
                            <span v-if="scope.row.type==3">api接口</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="url"
                        label="地址"
                        align="center"
                        width="235">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        label="备注"
                        align="center"
                        width="160">
                </el-table-column>
                <el-table-column
                        prop="code"
                        label="权限代码"
                        align="center"
                        width="160">
                </el-table-column>
                <el-table-column
                        prop="sort"
                        label="排序"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column  label="图标" align="center" width="100">
                        <template slot-scope="scope">
                            <i :class="scope.row.icon"></i>
                        </template>
                </el-table-column>
                <el-table-column  label="状态" align="center" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state==0">禁用</span>
                        <span v-if="scope.row.state==1">开启</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="353">
                    <template slot-scope="scope">
                        <el-button
                                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                                size="medium "
                                :type="scope.row.state==0?'primary':'warning'"
                                @click="handleProhibit(scope.$index, scope.row)">
                                {{scope.row.state==0?'开启':'禁用'}}
                        </el-button>
                        <el-button
                                size="medium "
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        :current-page="pages"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="currenttotal">
                </el-pagination>
            </div>
        </main>
<!--新增弹出框-->
        <el-dialog
                title="新增"
                :visible.sync="dialogVisible"
                width="38%">
                <template>
                    <el-form ref="permission" size="medium" :model="permission" label-width="100px">
                        <el-form-item label="类型">
                            <el-select @change="addTypeSelectChange" v-model="permission.type" placeholder="请选择类型">
                                <el-option v-for="item in permissionType" :key="item.value"
                                           :value="item.value" :label="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="上级菜单权限">
                            <el-cascader v-model="permission.upPermissionPid" placeholder="如果为第一级的则不用选" @change="changeProps" :options="addUpPermissions" :props="props" :show-all-levels="false">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="权限名称">
                            <el-input v-model="permission.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                        <el-form-item label="权限代码">
                            <el-input v-model="permission.code" placeholder="请输入代码" :disabled="true"></el-input>
                        </el-form-item>
                         <el-form-item label="权限代码">
                            <el-checkbox v-model="permission.permAnon" @change="permAnonChange">匿名访问</el-checkbox>
                            <el-checkbox v-model="permission.permAdmin" @change="permAdminChange">政府端登陆访问</el-checkbox>
                            <el-checkbox v-model="permission.permMem" @change="permMemChange">用户端登陆访问</el-checkbox>
                            <el-checkbox v-model="permission.permEnt" @change="permEntChange">企业端登陆访问</el-checkbox>
                            <el-checkbox v-model="permission.permControll" @change="permControllChange">权限控制访问</el-checkbox>
                        </el-form-item>
                        <el-form-item label="权限地址" v-if="permission.type!=2">
                            <el-input v-model="permission.url" placeholder="请输入地址"></el-input>
                        </el-form-item>
                        <el-form-item label="图标编码" v-if="permission.type==0 || permission.type==2">
                            <el-input v-model="permission.icon" placeholder="请输入编码"></el-input>
                        </el-form-item>
                        <el-form-item label="排序索引">
                            <el-input v-model="permission.sort"></el-input>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input v-model="permission.remark"></el-input>
                        </el-form-item>
                        <span>0-99 排序值越低越靠前</span>
                    </el-form>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData">确 定</el-button>
                </span>
        </el-dialog>
        <!--编辑弹出框-->
        <el-dialog
                title="编辑"
                :visible.sync="dialogEdit"
                width="38%">
            <template>
                <el-form ref="formEdit" size="medium " :model="formEdit" label-width="80px">
                    <el-form-item label="权限名称">
                        <el-input v-model="formEdit.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="权限地址" v-if="formEdit.type!=2">
                        <el-input v-model="formEdit.url" placeholder="请输入地址"></el-input>
                    </el-form-item>
                    <el-form-item label="权限编码">
                        <el-input v-model="formEdit.code" placeholder="请输入编码"></el-input>
                    </el-form-item>
                    <el-form-item label="图标编码" v-if="formEdit.type==0 || formEdit.type==2">
                        <el-input v-model="formEdit.icon" placeholder="请输入编码"></el-input>
                    </el-form-item>
                    <el-form-item label="排序索引">
                        <el-input v-model="formEdit.sort"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="formEdit.remark"></el-input>
                    </el-form-item>
                    <span>0-99 排序值越低越靠前</span>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogEdit = false">取 消</el-button>
                    <el-button type="primary" @click="postEditData">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "powers",
        data(){
            return{
                queryform:{},
                dialogVisible:false,//控制新增弹框
                dialogEdit:false,//控制编辑弹框
                formEdit:{},
                permissionType:[],//权限类型

                pages:1,//列表分页操作
                limits:10,
                currenttotal:null,

                permission:{
                    state:1,
                    code: "10000",
                    permAnon: true,
                    permAdmin: false,
                    permMem: false,
                    permEnt: false,
                    permControll: false,
                    uid:this.Util.getUid()
                },
                tableData: [],
                tableIndex:'',//记录操作列表的索引

                addUpPermissions: [],
                props: {
                    lazy: true,
                    value:'id',
                    checkStrictly:true,
                    label:'name',
                    lazyLoad: this.menusfather
                }
            }
        },
        created() {
            this.permissionType=this.Util.powerStype;
            this.getPowersData(this.queryform);
        },
        methods:{
            //添加权限表单菜单类型选择
            addTypeSelectChange(e){
                console.log('添加权限表单菜单类型选择', e);
                var _self=this;
                axios.get(_self.API.permission.addPermissionSelect,{
                    headers:{
                        token:_self.Util.getjwtToken(),
                    },
                    params:{
                        id: _self.Util.getUid(),
                        type: e,
                        pid: '0'
                    }
                }).then(function (pores) {
                    if(pores.data.success){
                        console.log('respo',pores);
                        _self.permission.upPermissionPid = '';
                        if(pores.data.data&&pores.data.data.length>0){
                            _self.addUpPermissions = pores.data.data
                        }else{
                            _self.addUpPermissions = [];
                        }
                    }
                }).catch(function (error) {
                    console.log('error', error);
                });
            },
            //根据父id获取类型菜单
            menusfather:function(node, resolve){
                var _self=this;
                var eid='0';
                if(node.data){
                    eid = node.data.id
                }
                axios.get(_self.API.permission.addPermissionSelect,{
                    headers:{
                        token:_self.Util.getjwtToken(),
                    },
                    params:{
                        id:_self.Util.getUid(),
                        type: this.permission.type,
                        pid:eid
                    }
                }).then(function (pores) {
                    if(pores.data.success){
                        console.log('respo',pores);
                        if(pores.data.data&&pores.data.data.length>0){
                            resolve(pores.data.data)
                        }else{
                            resolve([])
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            //获取到类型菜单id
            changeProps:function(arrid){
                this.permission.pid=arrid[arrid.length-1];
                console.log(arrid[arrid.length-1]);
            },
            permAnonChange: function(e){
                if(this.permission.permAnon){
                    this.permission.permAdmin = false; 
                    this.permission.permMem = false; 
                    this.permission.permEnt = false;  
                    this.permission.permControll = false;
                    this.permission.code = "10000";
                }
                //permission.code permission.permAnon permAdmin permMem permEnt  permControll
            }, 
            permAdminChange: function(e){
                this.permission.permAdmin = e;
                if(this.permission.permAdmin){
                    this.permission.permAnon = false;
                }else{
                    this.permission.permControll = false;
                }
                var code = "0";
                code += (this.permission.permAdmin?'1':'0');
                code += (this.permission.permMem?'1':'0');
                code += (this.permission.permEnt?'1':'0');
                code += (this.permission.permControll?'1':'0');
                this.permission.code = code;
            }, 
            permMemChange: function(e){
                this.permission.permMem = e;
                if(this.permission.permMem){
                    this.permission.permAnon = false;
                }
                var code = "0";
                code += (this.permission.permAdmin?'1':'0');
                code += (this.permission.permMem?'1':'0');
                code += (this.permission.permEnt?'1':'0');
                code += (this.permission.permControll?'1':'0');
                this.permission.code = code;
            }, 
            permEntChange: function(e){
                this.permission.permEnt = e;
                if(this.permission.permEnt){
                    this.permission.permAnon = false;
                }
                var code = "0";
                code += (this.permission.permAdmin?'1':'0');
                code += (this.permission.permMem?'1':'0');
                code += (this.permission.permEnt?'1':'0');
                code += (this.permission.permControll?'1':'0');
                this.permission.code = code;
            }, 
            permControllChange: function(e){
                this.permission.permControll = e;
                if(this.permission.permControll){
                    this.permission.permAnon = false;
                    this.permission.permAdmin = true;
                }
                var code = "0";
                code += (this.permission.permAdmin?'1':'0');
                code += (this.permission.permMem?'1':'0');
                code += (this.permission.permEnt?'1':'0');
                code += (this.permission.permControll?'1':'0');
                this.permission.code = code;
            }, 
            //获取权限菜单列表
            getPowersData:function (query) {
                var _self=this;
                axios.get(this.API.permission.list,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        name:query.name,
                        remark:query.Remarks,
                        state:query.state,
                        page:this.pages,
                        limit:this.limits
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _self.tableData=res.data.data.permis;
                        _self.currenttotal=res.data.data.count;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            //点击查询
            queryenu:function(){
                this.pages=1;
                this.getPowersData(this.queryform)
            },
            //点击新增打开弹框
            tableDataAdd:function(){
                this.dialogVisible=true;
            },
            //提交新增数据
            postAddData:function(){
                var _this=this;
                axios({
                    url:this.API.permission.add,
                    method: 'post',
                    data:this.permission,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function (res) {
                    if(res.data.success){
                        // _self.$message('ok')
                        console.log('resadd',res);
                        _this.permission={
                            state:1,
                            code: "10000",
                            permAnon: true,
                            permAdmin: false,
                            permMem: false,
                            permEnt: false,
                            permControll: false,
                            uid:_this.Util.getUid()
                        };
                        _this.dialogVisible=false;
                        _this.getPowersData(_this.queryform);//重新获取列表
                    }
                })
            },
            //列表操作，编辑，禁用，删除
            handleEdit:function(s,e){
                this.dialogEdit=true;
                this.tableIndex=s;
                this.formEdit=JSON.parse(JSON.stringify(e));
            },
            handleProhibit:function(s,e){
                if(e.state==1){
                    e.state=0;
                }else{
                    e.state=1;
                }
                this.formEdit=JSON.parse(JSON.stringify(e));
                this.postEditData();//调用编辑接口
            },
            handleDelete:function(s,e){
                var _self=this;
                this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _self.postDeleteData(s,e);
                })
            },
            //提交删除数据
            postDeleteData:function(index,listId){
                var _this=this;
                axios({
                    url:this.API.permission.delete,
                    method: 'delete',
                    params:{
                        id:listId.id,
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success('删除成功!');
                        _this.deleteItem(listId.id);
                        // _this.tableData.splice(index, 1);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            deleteItem: function(id){
                this.deleteItemDiGui(this.tableData, id);
            },
            deleteItemDiGui: function(table, id){
                if(table && table.length > 0){
                    for(var i =  0; i < table.length; i ++){
                        if(table[i].id == id){
                            table.splice(i, 1);
                            return;
                        }else{
                            this.deleteItemDiGui(table[i].children, id);
                        }
                    }
                }
            },
            //提交编辑数据
            postEditData:function(){
                var _this=this;
                axios({
                    url:this.API.permission.update,
                    method: 'post',
                    data:this.formEdit,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        console.log('编辑',res);
                        console.log(_this.tableIndex);
                        _this.$message.success(res.data.msg);
                        _this.setUpdateView(_this.tableData, res.data.data);
                        // _this.$set(_this.tableData,_this.tableIndex,res.data.data);
                        _this.dialogEdit=false;
                        // _this.getPowersData(_this.queryform);//重新获取列表
                    }else {
                        _this.$message.error(res.data.msg);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            setUpdateView: function(table, data){
                if(!table || table.length <= 0){
                    return;
                }
                for(var i = 0; i < table.length; i ++){
                    if(table[i].id == data.id){
                        data.children = table[i].children;
                        this.$set(table, i, data);
                    }
                    this.setUpdateView(table[i].children, data);
                }
            },
            //分页操作
            handleSizeChange(e){
                this.limits=e;
                this.getPowersData(this.queryform)
            },
            handleCurrentChange(e){
                this.pages=e;
                this.getPowersData(this.queryform);
            }
        }
    }
</script>

<style scoped lang="scss">

</style>